import type { App } from 'vue'
import AppModal from './app-modal.vue'
import AppModalContent from './app-modal-content.vue'
import AppModalDescription from './app-modal-description.vue'
import AppModalFooter from './app-modal-footer.vue'
import AppModalForm from './app-modal-form.vue'
import AppModalTitle from './app-modal-title.vue'

export default {
  install (app: App) {
    app.component('AppModal', AppModal)
    app.component('AppModalContent', AppModalContent)
    app.component('AppModalDescription', AppModalDescription)
    app.component('AppModalForm', AppModalForm)
    app.component('AppModalFooter', AppModalFooter)
    app.component('AppModalTitle', AppModalTitle)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AppModal: typeof AppModal,
    AppModalContent: typeof AppModalContent,
    AppModalDescription: typeof AppModalDescription,
    AppModalForm: typeof AppModalForm,
    AppModalFooter: typeof AppModalFooter,
    AppModalTitle: typeof AppModalTitle,
  }
}

declare global {
  type AppModal = InstanceType<typeof AppModal>
}
