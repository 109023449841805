<script setup lang="ts">
  import TheToast from '@/components/the-toast.vue'
  import ErrorMaintenance from '@/views/errors/error-503.vue'
  import { useAppInit } from './models/app'
  import { useAppStore } from './stores/app'
  import { useAuthStore } from './stores/auth'

  const app = useAppStore()
  const auth = useAuthStore()
  const { init } = useAppInit()
  init()
</script>

<template>
  <app-router-view v-if="auth.authenticated >= 0"></app-router-view>
  <the-toast></the-toast>
  <error-maintenance v-if="app.maintenance"></error-maintenance>
</template>
