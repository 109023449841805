<script setup lang="ts"></script>

<template>
  <div class="filter-bar">
    <slot></slot>
  </div>
</template>

<style lang="postcss" scoped>
  .filter-bar {
    @apply mb-4 flex items-center justify-end gap-4 max-sm:flex-col;

    :deep() > div {
      @apply max-sm:w-full;
    }
  }
</style>
