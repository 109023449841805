import { type RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/proyek',
    component: () => import('@/views/app/layout-app.vue'),
    children: [
      {
        path: '',
        name: 'project list',
        component: () => import('@/views/app/project/list/page-list.vue'),
        meta: {
          title: 'Daftar Proyek',
          breadcrumbs: [{
            label: 'Daftar Proyek',
          }],
        },
      },
      {
        path: ':id/:name?',
        name: 'project detail',
        component: () => import('@/views/app/project/detail/page-detail.vue'),
        props: true,
        meta: {
          title: 'Detail Proyek',
          breadcrumbs: [
            {
              label: 'Daftar Proyek',
              to: { name: 'project list' },
            },
            {
              label: 'Detail Proyek',
            },
          ],
        },
      },
    ],
  },
  {
    path: '/app',
    component: () => import('@/views/app/layout-app.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/app/home/page-home.vue'),
        meta: { title: 'Selamat Datang' },
      },
      {
        path: '/riwayat-perubahan',
        name: 'changelogs',
        component: () => import('@/views/app/changelog/page-changelog.vue'),
        meta: {
          title: 'Riwayat Perubahan',
          breadcrumbs: [{
            label: 'Riwayat Perubahan',
          }],
        },
      },
      {
        path: '/pengaturan/akun',
        name: 'account setting',
        component: () => import('@/views/app/setting/page-account.vue'),
        meta: {
          title: 'Pengaturan Akun',
          breadcrumbs: [{
            label: 'Pengaturan Akun',
          }],
        },
      },
      {
        path: '/pengaturan/password-baru',
        name: 'set new password',
        component: () => import('@/views/app/setting/page-new-password.vue'),
        meta: { title: 'Selamat Datang' },
      },
      {
        path: '/notifikasi',
        name: 'notification',
        component: () => import('@/views/app/notification/page-list.vue'),
        meta: { title: 'Notifikasi' },
      },
    ],
  },
  {
    path: '/auth',
    component: () => import('@/views/auth/layout-auth.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/page-login.vue'),
        meta: { title: 'Login' },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/errors/error-404.vue'),
    meta: { title: 'Halaman Tidak Ditemukan' },
  },
]
