<script setup lang="ts"></script>

<template>
  <div class="fixed inset-0 z-50 bg-white">
    <div class="pt-16 text-center text-2xl text-primary font-bold">
      Masa Building Solution
    </div>
    <app-img-empty image="maintenance.svg"></app-img-empty>
    <div class="px-4 text-center">
      <h1 class="mb-6 text-3xl text-primary font-bold tracking-wider">
        DALAM PERBAIKAN
      </h1>
      <div class="mt-4 text-lg text-gray-500">
        Kami akan segera kembali
        <br>
        Maaf atas ketidaknyamanannya
      </div>
    </div>
  </div>
</template>
