import { format, formatDistanceToNowStrict, isToday, isYesterday } from 'date-fns'
import { id } from 'date-fns/locale'

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
const gradinFormat = (dt: string | number | Date, fmt: string = 'PP') => {
  const locale = window.navigator.language === 'id' ? id : undefined
  return format(dt, fmt, { locale })
}
export default {
  /**
   * Format date with given string format. Set locale to id
   * See https://date-fns.org/docs/format for accepted tokens
   */
  date: gradinFormat,
  dateLong: (dt: string | number | Date) => {
    return gradinFormat(dt, 'cccc, dd MMMM yyyy HH:mm')
  },

  /**
   * Shorter format date. If date is today, it will only show time,
   * otherwise show the date.
   */
  dateShort: (dt: string | number | Date) => {
    if (isToday(dt)) {
      return gradinFormat(dt, 'HH:mm')
    }
    if (isYesterday(dt)) {
      return 'Kemarin'
    }

    return gradinFormat(dt)
  },

  /**
   * Get texts of calculation given date from now.
   * e.g: 2 hari yang lalu, kemarin, 5 jam yang lalu.
   * See https://date-fns.org/docs/formatDistanceToNow.
   */
  dateRelative: (dt: string | number | Date, addSuffix = true) => {
    return formatDistanceToNowStrict(new Date(dt), { locale: id, addSuffix })
  },
}
