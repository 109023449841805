<script setup lang="ts">
  import { ref } from 'vue'
  import AppAttachmentPreview from '@/components/app-attachment-preview.vue'
  import AppAttachmentThumbnail from '@/components/app-attachment-thumbnail.vue'

  defineProps<{
    attachments: (File | Attachment | AttachmentUploading)[],
    disabled?: boolean,
  }>()
  const emit = defineEmits<{
    (e: 'change', files: File[]): void,
    (e: 'attachment:remove', index: number): void,
  }>()
  const onFileChange = (files: File[]) => {
    emit('change', files)
  }
  const removeAttachment = (index: number) => {
    emit('attachment:remove', index)
  }
  const refPreview = ref<InstanceType<typeof AppAttachmentPreview>>()
</script>

<template>
  <div>
    <div class="space-y-4">
      <app-input-file
        v-if="!disabled"
        v-slot="{ open }"
        multiple
        @change="onFileChange">
        <div class="flex flex-wrap items-baseline gap-2">
          <app-button
            class="btn-low-priority flex-shrink-0 space-x-2"
            @click="open()">
            <fa-icon icon="i-far-folder-open"></fa-icon>
            <span>Upload file</span>
          </app-button>
          <span class="text-sm">Bisa paste gambar, atau drag & drop file kesini</span>
        </div>
      </app-input-file>
      <div
        v-if="attachments.length"
        class="flex flex-wrap gap-4">
        <app-attachment-thumbnail
          v-for="(file, index) in attachments"
          :key="index"
          :file="file"
          :can-delete="!disabled"
          @remove="removeAttachment(index)"
          @click="refPreview?.open(index)"></app-attachment-thumbnail>
      </div>
    </div>
    <app-attachment-preview
      ref="refPreview"
      :files="attachments"></app-attachment-preview>
  </div>
</template>
