<script setup lang="ts">
  import { computed } from 'vue'
  import { fmt, randomId } from '@/functions'

  const props = defineProps<{
    id?: string,
    modelValue: string,
    type?: string,
    label?: string,
    placeholder?: string,
    required?: boolean,
    error?: string | string[],
    maxLength?: number,
    rows?: number,
    disabled?: boolean,
    autocomplete?: string,
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void,
  }>()

  const value = computed({
    set: (val) => {
      emit('update:modelValue', val)
    },
    get: () => props.modelValue,
  })

  const onInput = (event: Event) => {
    const eventTarget = event.target as HTMLInputElement
    emit('update:modelValue', eventTarget.value)
  }

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? defaultId)

  defineOptions({
    inheritAttrs: false,
  })
</script>

<template>
  <div v-bind="$attrs">
    <label
      v-if="label"
      :for="inputId"
      class="form-label">
      {{ label }}
      <span
        v-if="required"
        class="text-red-600">
        *
      </span>
    </label>
    <div class="relative flex items-center">
      <textarea
        v-if="type === 'textarea'"
        :id="inputId"
        class="form-input min-h-24"
        :disabled="disabled"
        :value="value"
        :placeholder="placeholder"
        :rows="rows ?? 3"
        :required="required"
        :maxlength="maxLength"
        @input="onInput"></textarea>
      <input
        v-else
        :id="inputId"
        class="form-input"
        :disabled="disabled"
        :autocomplete="autocomplete ?? 'off'"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :required="required"
        :maxlength="maxLength"
        @input="onInput">
      <div
        v-if="maxLength !== undefined"
        class="absolute bottom-1 right-2 bg-white text-3xs text-gray-500">
        {{ fmt.number(maxLength - value.length) }}
      </div>
      <slot name="inline"></slot>
    </div>
    <slot></slot>
    <app-error-msg :msg="error"></app-error-msg>
  </div>
</template>

<style lang="postcss">

.form-label {
  @apply block text-left text-sm font-medium leading-7;
}
.form-input {
  @apply block w-full rounded h-9 px-4 text-sm focus:input-ring border placeholder:text-gray-300;
}
textarea.form-input {
  @apply pb-4 pt-2;
}
</style>
