<script setup lang="ts">
  // import { AnchorHTMLAttributes, ButtonHTMLAttributes, computed } from 'vue'
  import type { ButtonHTMLAttributes } from 'vue'
  import { computed } from 'vue'
  import type { RouteLocationRaw } from 'vue-router'

  // interface Props extends ButtonHTMLAttributes, AnchorHTMLAttributes {
  interface Props {
    to?: RouteLocationRaw,
    loading?: boolean,
    disabled?: boolean,
    type?: ButtonHTMLAttributes['type'],
    href?: string,
  }

  const props = defineProps<Props>()

  const is = computed(() => {
    if (props.disabled || props.loading) {
      return 'button'
    }
    if (props.to) {
      return 'router-link'
    }
    if (props.href) {
      return 'a'
    }

    return 'button'
  })

  // render as link when `to` is provided, and props.disabled == false
  // otherwise, render as button
</script>

<template>
  <component
    :is="is"
    :to="to"
    :target="href ? '_blank' : undefined"
    :type="type ?? 'button'"
    :disabled="disabled || loading"
    class="disabled:cursor-not-allowed">
    <slot></slot>
    <app-loading-dot v-if="loading"></app-loading-dot>
  </component>
</template>

<style lang="postcss">
  /* .btn is defined in uno.config.ts as shortcuts so it can be used with apply */
.btn-primary {
  @apply btn bg-primary hover:bg-primary-400 text-white;
}

.btn-danger {
  @apply btn bg-red-500 text-white hover:bg-red-400;
}

.btn-low-priority {
  @apply btn border-gray-200 hover:bg-gray-100;
}

.buttons {
  @apply space-x-2 whitespace-nowrap;

  button,
  a {
    @apply text-gray-400 hover:text-gray-600 hover:disabled:text-gray-400;

    &:disabled {
      @apply cursor-not-allowed text-gray-200 hover:text-gray-200;
    }
  }
}

.btn-icon {
  @apply size-9 p-2 text-sm;
}
</style>
