import { watchDebounced } from '@vueuse/core'
import { reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import { useApi } from '@/functions/api'
import { useAuthStore } from '@/stores/auth'

export function useAppInit () {
  const route = useRoute()
  const router = useRouter()
  const auth = useAuthStore()
  const api = useApi()

  const init = async () => {
    try {
      const response = await api.GET<{ user: AuthenticatedUser }>('worker/app/init')
      auth.setUser(response.user)

      if (response.user.require_new_password) {
        await router.replace({ name: 'set new password' })
      } else if (route.name === 'login') {
        await router.replace('/' + (route.query.r ?? ''))
      }

      // setTimeout(() => {
      // when user authenticated but opening login page
      // settimeout to prevent flashing login page, let
      // the redirect above to take effect first
      auth.setAuthenticated(1)
      // }, 200)
    } catch (error) {
      // setTimeout(() => {
      // when user not authenticated but opening page other than
      // login, forget password, forbidden, let the redirect
      // to login page take effect first
      auth.setAuthenticated(0)
      // }, 200)
    }
  }

  return {
    init,
  }
}

export function useLogout () {
  const router = useRouter()
  const auth = useAuthStore()
  const api = useApi()
  // const { unsubscribeBeam } = usePusherBeam(auth.user.id)

  return () => {
    api.POST('logout')
    // unsubscribeBeam()
    router.push({ name: 'login' })
    auth.$reset()
    auth.setAuthenticated(0)
  }
}

export function useGlobalSearch () {
  const api = useApi()
  const search = ref('')
  const loading = ref(false)
  const results = reactive<{
    projects: Project[],
  }>({
    projects: [],
  })
  const getData = async () => {
    try {
      const response = await api.GET<{ projects: Project[] }>('worker/app/search', {
        search: search.value,
      })
      results.projects = response.projects
    } finally {
      loading.value = false
    }
  }

  watch(search, () => {
    if (search.value) {
      loading.value = true
    }
  })
  watchDebounced(search, () => {
    if (search.value) {
      getData()
    }
  }, { debounce: 500 })

  return {
    search,
    loading,
    results,
  }
}
