import { createRouter, createWebHistory } from 'vue-router'
import { useAppStore } from '@/stores/app'
import { routes } from './routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach((to, _, next) => {
  const app = useAppStore()

  if (!to.meta.title) {
    throw new Error('Page must have a title.')
  }

  app.setPageTitle(to.meta.title)
  app.setBreadcrumbs(to.meta.breadcrumbs)

  next()
})

export default router

/**
 * Make sure all routes has meta with these attributes.
 */
declare module 'vue-router' {
  interface RouteMeta {
    title: string,
    breadcrumbs?: {
      to?: RouteLocationRaw,
      label: string,
    }[],
  }
}
