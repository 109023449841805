<script setup lang="ts">
  import { ref, watch } from 'vue'

  const props = defineProps<{
    src: string | null,
    alt?: string | null,
  }>()

  const error = ref(false)

  watch(
    () => props.src,
    () => {
      error.value = false
    },
  )
</script>

<template>
  <div class="overflow-hidden">
    <div
      v-if="!src || error"
      class="flex-center size-full bg-gray-200">
      <fa-icon
        icon="i-fas-image"
        class="text-gray-400"></fa-icon>
    </div>
    <img
      v-else
      class="size-full"
      :src="src"
      :alt="alt ?? ''"
      @error="error = true">
  </div>
</template>
