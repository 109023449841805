<script setup lang="ts">
  import { ref } from 'vue'
  import AppAttachmentInput from '@/components/app-attachment-input.vue'
  import MarkdownEditor from './markdown-editor.vue'

  const props = defineProps<{
    allowAttachment?: boolean,
    placeholder?: string,
  }>()

  const text = defineModel<string>({ default: '' })
  const attachments = defineModel<File[]>('attachments', { default: [] })

  const writing = ref(true)

  function onFileChange (files: File[]) {
    attachments.value = attachments.value.concat(files)
  }

  const onPaste = (event: ClipboardEvent) => {
    if (!props.allowAttachment) {
      return
    }
    const items = Array.from(event.clipboardData?.items ?? [])
    if (items[1]?.kind === 'file') {
      const blob = items[1].getAsFile()
      if (blob) {
        onFileChange([blob])
      }
    }
  }

  const refEditor = ref<InstanceType<typeof MarkdownEditor>>()
  const setFocus = () => {
    refEditor.value?.setFocus()
  }
  defineExpose({
    setFocus,
  })
</script>

<template>
  <div class="relative">
    <!-- btn writing / see preview -->
    <div class="border-b px-2 py-1 -mb-px">
      <button
        type="button"
        class="px-2 py-1"
        :class="writing ? 'font-bold' : ''"
        @click="writing = true">
        Tulis
      </button>
      <button
        type="button"
        class="px-2 py-1"
        :class="!writing ? 'font-bold' : ''"
        @click="writing = false">
        Lihat Hasil
      </button>
    </div>

    <!-- text editor / preview -->
    <template v-if="writing">
      <div @paste="onPaste">
        <markdown-editor
          ref="refEditor"
          v-model="text"
          :placeholder="placeholder"></markdown-editor>
      </div>
    </template>
    <template v-else>
      <div class="min-h-80 p-4">
        <app-markdown-preview :text="text || ''"></app-markdown-preview>
      </div>
    </template>

    <!-- attachments preview -->
    <div
      v-if="allowAttachment"
      class="border-t py-4">
      <app-attachment-input
        :attachments="attachments"
        class="px-4"
        @change="onFileChange"
        @attachment:remove="attachments.splice($event, 1)"></app-attachment-input>
    </div>

    <app-drop-zone
      class="absolute inset-0 rounded-lg"
      @file-dropped="onFileChange"></app-drop-zone>
  </div>
</template>
