import { defineStore } from 'pinia'

type State = {
  user: AuthenticatedUser,
  authenticated: -1 | 0 | 1, // 0 = false, 1 = true, -1 = unknown
}

export const useAuthStore = defineStore('Auth', {
  state: (): State => ({
    user: {
      id: 0,
      email: '',
      name: '',
      permissions: [],
      role: { id: 0, name: '' },
      username: '',
      photo_url: null,
      photo_url_xs: null,
      require_new_password: false,
    },
    authenticated: -1,
  }),
  getters: {
    userCan: (state) => (permission: string) => {
      return state.user?.permissions.some((p) => p.toLowerCase() === permission.toLowerCase())
    },
  },
  actions: {
    setUser (user: Partial<AuthenticatedUser>) {
      this.user = { ...this.user, ...user }
    },
    setAuthenticated (authenticated: -1 | 0 | 1) {
      this.authenticated = authenticated
    },
  },
})
