<script setup lang="ts">
  import {
    Dialog as AppDialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue'
  import { ref } from 'vue'

  defineProps<{
    title?: string,
    description?: string,
  }>()

  const isOpen = ref(false)

  const open = () => {
    isOpen.value = true
  }
  const close = () => {
    isOpen.value = false
  }

  defineExpose({ open, close })
</script>

<template>
  <teleport to="body">
    <transition-root
      appear
      :show="isOpen"
      as="template">
      <app-dialog
        as="div"
        class="relative z-30"
        @close="close">
        <transition-child
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <div class="fixed inset-0 bg-black bg-opacity-40"></div>
        </transition-child>

        <div class="fixed inset-0 flex-center p-4">
          <transition-child
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <dialog-panel class="dialog-panel">
              <button
                class="absolute right-4 top-5 h-8 w-8 flex-center rounded-full text-gray-500 hover:bg-gray-200"
                @click="close">
                <fa-icon icon="i-fas-xmark"></fa-icon>
              </button>

              <app-modal-title v-if="title">
                {{ title }}
              </app-modal-title>

              <div class="dialog-content">
                <slot></slot>
              </div>
            </dialog-panel>
          </transition-child>
        </div>
      </app-dialog>
    </transition-root>
  </teleport>
</template>

<style lang="postcss" scoped>
  .dialog-panel {
    @apply max-w-full w-100 transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all;
  }
  .dialog-content {
    /* ensure the modal content height will not surpass screen height */
    @apply flex flex-col max-h-[calc(100vh_-_8rem)] lg:max-h-[calc(100vh_-_12rem)] 2xl:max-h-[calc(100vh_-_16rem)];
  }
</style>
