<script setup lang="ts">
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { fmt } from '@/functions'

  const props = defineProps<{
    pagination?: Pagination,
  }>()

  const route = useRoute()
  const currentPage = computed(() => Number(route.query.page ?? 1))
  const lastPage = computed(() => props.pagination?.last_page ?? 1)
  const pages = computed(() => {
    const pages = [currentPage.value]
    let i = 1
    while (pages.length < 5 && pages.length < lastPage.value) {
      if (currentPage.value - i > 0) {
        pages.unshift(currentPage.value - i)
      }
      if (currentPage.value + i <= lastPage.value) {
        pages.push(currentPage.value + i)
      }
      i++
    }
    return pages
  })

  const getRoute = (p: number | string) => {
    const page = p === 1 ? undefined : p
    return { query: { ...route.query, page } }
  }
</script>

<template>
  <nav
    v-if="pagination && lastPage > 1"
    role="navigation"
    aria-label="Pagination"
    class="mt-4">
    <div class="mb-2 text-center text-xs">
      Menampilkan
      <span class="font-bold">
        {{ fmt.number(pagination.from) }}-{{ fmt.number(pagination.to) }}
      </span>
      dari
      <span class="font-bold">{{ fmt.number(pagination.total) }}</span>
    </div>

    <div class="overflow-auto scrollbar-none flex">
      <div class="space-x-1 flex mx-auto">
        <router-link
          v-if="pages[0] - 1 >= 1"
          :to="getRoute(1)"
          class="button-pagination">
          <fa-icon
            icon="i-far-chevrons-left"
            class="text-3xs"></fa-icon>
        </router-link>
        <router-link
          v-if="currentPage > 1"
          :to="getRoute(currentPage - 1)"
          class="button-pagination">
          <fa-icon
            icon="i-far-chevron-left"
            class="text-3xs"></fa-icon>
        </router-link>
        <router-link
          v-for="page in pages"
          :key="page"
          :to="getRoute(page)"
          class="button-pagination"
          :class="{
            active: Number(currentPage) === page,
          }">
          {{ fmt.number(page) }}
        </router-link>
        <router-link
          v-if="currentPage + 1 <= lastPage"
          :to="getRoute(currentPage + 1)"
          class="button-pagination">
          <fa-icon
            icon="i-far-chevron-right"
            class="text-3xs"></fa-icon>
        </router-link>
        <router-link
          v-if="pages[pages.length - 1] + 1 <= lastPage"
          :to="getRoute(lastPage)"
          class="button-pagination">
          <fa-icon
            icon="i-far-chevrons-right"
            class="text-3xs"></fa-icon>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<style lang="postcss" scoped>
  .button-pagination {
    @apply size-8 rounded-lg border bg-white text-center text-xs;
    @apply flex items-center justify-center text-gray-700 hover:bg-gray-100 flex-shrink-0;
  }
  .active {
    @apply bg-primary-500 border-primary-500 hover:bg-primary-500 text-white;
  }
</style>
