import { type App } from 'vue'
import AppBadge from './app-badge.vue'
import AppButton from './app-button.vue'
import AppButtonBack from './app-button-back.vue'
import AppCheckbox from './app-checkbox.vue'
import AppDropZone from './app-drop-zone.vue'
import AppErrorMsg from './app-error-msg.vue'
import AppForm from './app-form.vue'
import AppFormSection from './app-form-section.vue'
import AppImg from './app-image.vue'
import AppImgEmpty from './app-image-empty.vue'
import AppInput from './app-input.vue'
import AppInputFile from './app-input-file.vue'
import AppInputPassword from './app-input-password.vue'
import AppLoadingDot from './app-loading-dot.vue'
import AppNotice from './app-notice.vue'
import AppPagination from './app-pagination.vue'
import AppRouterView from './app-router-view.vue'
import AppSearch from './app-search.vue'
import AppSearchPage from './app-search-page.vue'
import AppSubmit from './app-submit.vue'
import AppTextShowMore from './app-text-show-more.vue'
import AppUserPhoto from './app-user-photo.vue'
import UseCard from './card'
import useDropdown from './dropdown'
import FaIcon from './fa-icon.vue'
import UseMarkdown from './markdown'
import UseModal from './modal/'
// import AppTableCheckable from './table/app-table-checkable.vue'
// import AppTable from './table/app-table.vue'

export default {
  install (app: App) {
    app.component('AppBadge', AppBadge)
    app.component('AppButton', AppButton)
    app.component('AppButtonBack', AppButtonBack)
    app.component('AppCheckbox', AppCheckbox)
    app.component('AppDropZone', AppDropZone)
    app.component('AppErrorMsg', AppErrorMsg)
    app.component('AppForm', AppForm)
    app.component('AppFormSection', AppFormSection)
    app.component('AppImg', AppImg)
    app.component('AppImgEmpty', AppImgEmpty)
    app.component('AppInput', AppInput)
    app.component('AppInputFile', AppInputFile)
    app.component('AppInputPassword', AppInputPassword)
    app.component('AppLoadingDot', AppLoadingDot)
    app.component('AppNotice', AppNotice)
    app.component('AppPagination', AppPagination)
    app.component('AppRouterView', AppRouterView)
    app.component('AppSearch', AppSearch)
    app.component('AppSearchPage', AppSearchPage)
    app.component('AppSubmit', AppSubmit)
    app.component('AppTextShowMore', AppTextShowMore)
    app.component('AppUserPhoto', AppUserPhoto)
    app.component('FaIcon', FaIcon)
    app.use(UseCard)
    app.use(useDropdown)
    app.use(UseMarkdown)
    app.use(UseModal)
    // app.component('AppTableCheckable', AppTableCheckable)
    // app.component('AppTable', AppTable)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AppBadge: typeof AppBadge,
    AppButton: typeof AppButton,
    AppButtonBack: typeof AppButtonBack,
    AppCheckbox: typeof AppCheckbox,
    AppDropZone: typeof AppDropZone,
    AppErrorMsg: typeof AppErrorMsg,
    AppForm: typeof AppForm,
    AppFormSection: typeof AppFormSection,
    AppImg: typeof AppImg,
    AppImgEmpty: typeof AppImgEmpty,
    AppInput: typeof AppInput,
    AppInputFile: typeof AppInputFile,
    AppInputPassword: typeof AppInputPassword,
    AppLoadingDot: typeof AppLoadingDot,
    AppNotice: typeof AppNotice,
    AppPagination: typeof AppPagination,
    AppRouterView: typeof AppRouterView,
    AppSearch: typeof AppSearch,
    AppSearchPage: typeof AppSearchPage,
    AppSubmit: typeof AppSubmit,
    AppTextShowMore: typeof AppTextShowMore,
    AppUserPhoto: typeof AppUserPhoto,
    FaIcon: typeof FaIcon,
  }
}
