import { defineStore } from 'pinia'
import { type RouteLocationRaw } from 'vue-router'

const APP_TITLE = import.meta.env.VITE_APP_TITLE ?? 'Panel Tim Kerja'
type AppBreadcrumb = {
  to?: RouteLocationRaw,
  label: string,
}
type State = {
  pageTitle: string,
  unreadNotificationsCount: number,
  userDraggingFile: boolean,
  breadcrumbs: {
    to?: RouteLocationRaw,
    label: string,
  }[],
  maintenance: boolean,
}

export const useAppStore = defineStore('Page', {
  state: (): State => ({
    pageTitle: '',
    unreadNotificationsCount: 0,
    userDraggingFile: false,
    breadcrumbs: [],
    maintenance: false,
  }),
  actions: {
    setPageTitle (title: string) {
      this.pageTitle = title
      document.title = `${title} | ${APP_TITLE}`
    },
    setUnreadNotificationsCount (count: number) {
      this.unreadNotificationsCount = count
    },
    setBreadcrumbs (breadcrumbs?: AppBreadcrumb[]) {
      this.breadcrumbs = breadcrumbs ?? []
    },
    setUserDraggingFile (userDraggingFile: boolean) {
      this.userDraggingFile = userDraggingFile
    },
    setMaintenance (maintenance: boolean) {
      this.maintenance = maintenance
    },
  },
})
