<script setup lang="ts"></script>

<template>
  <div class="ellipsis-menu">
    <app-dropdown>
      <div class="size-8 text-xl">
        <fa-icon icon="i-far-ellipsis-vertical"></fa-icon>
      </div>

      <template #items>
        <slot></slot>
      </template>
    </app-dropdown>
  </div>
</template>

<style lang="postcss">
  .card-content .ellipsis-menu {
    @apply absolute top-4 right-2;
  }
</style>
